<template>
    <div class="c-form__group">
        <button type="submit" class="c-btn c-btn--submit">{{ comp.settings.value }}</button>
    </div>
</template>

<script>
  export default {
    name: 'Comp_formButton',
    props: {
      'comp': Object
    }
  }
</script>
